import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultEmailTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_email_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListEmailTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_email_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getEmailTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_email_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEmailTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_email_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editEmailTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_email_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEmailTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_email_template', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getEmailTemplateOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/email_template_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
